<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.RESELLER')} (*)`"
      :placeholder="$t('COMMON.RESELLER')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <reseller-selector
        :reseller="resellerService.reseller.id"
        :filterable="true"
        :showAll="false"
        @resellerChanged="
          (resellerId) => {
            resellerService.reseller.id = resellerId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <image-selector
      :label="$t('COMMON.PICTURE')"
      :defaultImage="resellerService.picture"
      ressource_name="resellerServices"
      :ressource_id="resellerService.id ? resellerService.id : 0"
      field="picture"
      @imageChanged="
        (file_url) => {
          resellerService.picture = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <gallery-selector
      :label="$t('COMMON.GALLERY')"
      :defaultGallery="resellerService.gallery"
      ressource_name="resellerServices"
      :ressource_id="resellerService.id ? resellerService.id : 0"
      field="gallery"
      @galleryChanged="
        (gallery_urls) => {
          resellerService.gallery = gallery_urls;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="resellerService.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="resellerService.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.PRICE')} (*)`"
      :placeholder="$t('COMMON.PRICE')"
      v-model="resellerService.price"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.price" />

    <taxes-selector
      :label="$t('COMMON.TAXES')"
      :taxes="resellerService.taxes"
      @taxesChanged="
        (taxes) => {
          resellerService.taxes = taxes;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.taxes" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          resellerService.id
            ? $t("RESELLER_SERVICES.EDIT_RESELLER_SERVICE")
            : $t("RESELLER_SERVICES.ADD_RESELLER_SERVICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    GallerySelector,
    TaxesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["resellerServiceData", "formErrors", "loading"],

  data() {
    let resellerServiceData = cloneDeep(this.resellerServiceData);
    resellerServiceData = this.$fillUserResellerData(resellerServiceData);
    return {
      resellerService: resellerServiceData,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let resellerServiceData = cloneDeep(this.resellerService);
      resellerServiceData = this.$fillUserResellerData(resellerServiceData);
      this.$emit("resellerServiceSubmitted", resellerServiceData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    resellerServiceData(resellerServiceData) {
      if (resellerServiceData) {
        this.resellerService = {
          ...this.resellerService,
          ...cloneDeep(resellerServiceData),
        };
      }
    },
  },
};
</script>
