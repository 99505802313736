var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.RESELLER')) + " (*)"),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.resellerService.reseller.id,"filterable":true,"showAll":false},on:{"resellerChanged":function (resellerId) {
          _vm.resellerService.reseller.id = resellerId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}}),_c('image-selector',{attrs:{"label":_vm.$t('COMMON.PICTURE'),"defaultImage":_vm.resellerService.picture,"ressource_name":"resellerServices","ressource_id":_vm.resellerService.id ? _vm.resellerService.id : 0,"field":"picture"},on:{"imageChanged":function (file_url) {
        _vm.resellerService.picture = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.GALLERY'),"defaultGallery":_vm.resellerService.gallery,"ressource_name":"resellerServices","ressource_id":_vm.resellerService.id ? _vm.resellerService.id : 0,"field":"gallery"},on:{"galleryChanged":function (gallery_urls) {
        _vm.resellerService.gallery = gallery_urls;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.resellerService.name),callback:function ($$v) {_vm.$set(_vm.resellerService, "name", $$v)},expression:"resellerService.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.resellerService.excerpt),callback:function ($$v) {_vm.$set(_vm.resellerService, "excerpt", $$v)},expression:"resellerService.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.PRICE')) + " (*)"),"placeholder":_vm.$t('COMMON.PRICE')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.resellerService.price),callback:function ($$v) {_vm.$set(_vm.resellerService, "price", $$v)},expression:"resellerService.price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.price}}),_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.TAXES'),"taxes":_vm.resellerService.taxes},on:{"taxesChanged":function (taxes) {
        _vm.resellerService.taxes = taxes;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxes}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.resellerService.id ? _vm.$t("RESELLER_SERVICES.EDIT_RESELLER_SERVICE") : _vm.$t("RESELLER_SERVICES.ADD_RESELLER_SERVICE"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }